/**
 *
 * http配置
 */

import axios from 'axios';
import router from '@/router/index';
import store from '@/vuex/index';


// axios 配置
axios.defaults.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Authorization'] = 'QcCsTbg3hXvB%2fcfkukVXv75bDAcA9d%2fmI5aHwK%2bjXp6bX7SwYoHLhNw9NNYaVtEpwqBxxpdBWWPG%2fB%2b6R4PlPVM%2bhYMnYJc2PhglB%2bSFBBGNUxtqQ2fqI3CsYLSfK5JHCxTyJgHSju00HZuobLtYOZ1cWUoPqvwmEIC9Y3f%2fotIURR0U4bd%2fdCjB2DTZtTwtw%2fJ10Rt%2bChJmy30Nl2ymSZU%2b0ePSl3v1MRENg57HPVY%3d';

// axios.defaults.headers.post['Authorization'] = store.state.token;

// axios.defaults.headers.post['Platform'] = store.state.platform;

// if(store.state.token){
//   console.log('使用用户token')
//   axios.defaults.headers.post['Authorization'] = store.state.token;
// }

axios.defaults.baseURL = 'https://panel.bx.oz4.cn/';
// axios.defaults.baseURL = 'http://192.168.3.33:8866/';

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers['content-type'] = 'application/json';
    return config;
  },
  err => Promise.reject(err),
);

// http response 拦截器
axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // alert('401');
          // 401 清除token信息并跳转到登录页面
          store.commit(types.LOGOUT);
          router.replace({
            name: 'signin',
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
        case 403:
          // alert('403');
          // 403 清除token信息 并且去登陆
          store.commit(types.LOGOUT);
          // dialog.toast({mes: '需要登陆后才能操作！' ,timeout: 1500})
          router.replace({
            name: 'signin',
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
      }
    }
    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    return Promise.reject(error.response.data);
  },
);


export default {
  // fetchPost  请求方式
  fetchPost(url, params) {
    return new Promise((resolve, reject) => {
      const data = {
      };
      if(store.state.token){
        data.token = store.state.token;
      }
      if (store.state.manageState) {
        data.site_id = store.state.manageState;
      }
      // 参数合并
      Object.assign(data, params);
      
      axios.post(url, data)
        .then((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // GET 请求方式
  fetchGet(url, params) {
    return new Promise((resolve, reject) => {
      const data = {
      };
      if(store.state.token){
        data.token = store.state.token;
      }
      if (store.state.manageState) {
        data.site_id = store.state.manageState;
      }
      // 参数合并
      Object.assign(data, params);
      axios.get(url, {params:data})
        .then((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
