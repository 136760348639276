export default [
  // 开播端

  // 开播首页
  {
    name: 'index',
    path: '/',
    meta: {
      title: '首页',
      show_side: true, // 控制是否显示在左侧菜单
      auth: true,
      box: 'main',
    },
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index.vue'),
  },
  // 开播端
  {
    name: 'push',
    path: '/push',
    meta: {
      title: '开播',
      show_side: true, // 控制是否显示在左侧菜单
      auth: true,
      box: 'main',
    },
    component: () => import(/* webpackChunkName: "push" */ '@/views/push/push.vue'),
  },

  // im
  {
    name: 'im',
    path: '/im',
    meta: {
      title: '开播',
      show_side: true, // 控制是否显示在左侧菜单
      auth: true,
      box: 'main',
    },
    component: () => import(/* webpackChunkName: "im" */ '@/views/im/im.vue'),
  },
  // 开播端
  {
    name: 'chat-review',
    path: '/chat-review',
    meta: {
      title: '开播',
      show_side: true, // 控制是否显示在左侧菜单
      auth: true,
      box: 'main',
    },
    component: () => import(/* webpackChunkName: "chat-review" */ '@/views/chat-review/chat-review.vue'),
  },
  // 数据大屏
  {
    name: 'data',
    path: '/data',
    meta: {
      title: '开播',
      show_side: true, // 控制是否显示在左侧菜单
      auth: true,
      box: 'main',
    },
    component: () => import(/* webpackChunkName: "data" */ '@/views/room/data/data.vue'),
  },
  // 公开页面
  {
    name: 'signin',
    path: '/signin',
    meta: {
      title: '登录',
      box: 'sign',
    },
    component: () => import(/* webpackChunkName: "signin" */ '@/views/public/signin.vue'),
  },
  {
    name: 'signup',
    path: '/signup',
    meta: {
      title: '注册',
      box: 'sign',
    },
    component: () => import(/* webpackChunkName: "signup" */ '@/views/public/signup.vue'),
  },
];
