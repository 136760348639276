
import room_state from './room_state';

const state = {
  token: '',
  mate: {
    title: '',
    keywords: '',
    description: '',
  },
  brands: {
  },
  manageState: false,
};

Object.assign(state, room_state);

export default state;
