import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/index';
import store from '@/vuex/index';

import axios from 'axios';

/* vue meta */
import Meta from 'vue-meta';


/* iview */
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

// iconfont 图标库
import '@/assets/icon/iconfont.js';
// iconfont
import icon_svg from '@/components/icon'; // 引入 api

/* css */
import '@/assets/css/reset.css';
import '@/assets/css/base.css';
import '@/assets/css/common.css';
import '@/assets/css/responsive.css';

/* api */
import api from '@/api/api.js';

/* 公共方法 */
import util from '@/assets/js/utils.js';

/* 动画库 */
import animate from 'animate.css';

/* Qrcode */
import QRcode from '@/components/qrcode/qrcode'; // Qrcode

/* copy */
import copy from '@/components/mash-copy/mash-copy'; // 引入 api


import eventBus from 'utils/_eventBus.js';
import 'assets/icons';


/* 数字动画 */
import AnimateNumber from 'vue-animate-number'; // 请求管理器
Vue.prototype.$axios = axios;
Vue.use(Meta);
// import '@/assets/css/theme.less';
Vue.use(ViewUI);
Vue.component('icon-svg', icon_svg);
Vue.prototype.$api = api;

Vue.prototype.$window = window;
Vue.prototype.$util = util;
Vue.use(animate);
Vue.component('QRcode', QRcode);
Vue.component('copy', copy);
Vue.use(AnimateNumber);

Vue.prototype.$eventBus = eventBus;
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
