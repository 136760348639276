
const room_state = {
  // 直播间阶段
  liveStage: 'not_started',
  // 用户信息
  userInfo: {
    userId: '',
    userName: '',
    userAvatar: '',
  },
  // 是否在屏幕分享中
  isScreenSharing: false,
  // 使用的摄像头设备
  activeCamera: {

  },
  // 使用的麦克风设备
  activeMicrophone: {

  },
  // 使用的扬声器设备
  activeSpeaker: {

  },

  // 视频参数
  videoProfile: {
    width: 1280,
    height: 720,
    frameRate: 30,
    bitrate: 2000,
  },

  // 屏幕分享参数
  screenProfile: {
    width: 1280,
    height: 720,
    frameRate: 15,
    bitrate: 1600,
  },

  // 音频采集状态
  isAudioMuted: false,
  // 视频采集状态
  isVideoMuted: false,
  // 音量大小
  audioLevel: 0,
  // 上行网络质量等级
  uplinkQualityLevel: 1,
};

export default room_state;
