<template>
  <div class="QRCode" :style="{'width':width, 'height':height}">
    <canvas :id="canvasId" :style="{'width':width, 'height':height}"></canvas>
  </div>
</template>
<script>
import QRCode from 'qrcode';
export default {
  name: 'QRCode',
  props: {
    content: {
      type: String,
      default: '测试二维码',
    },
    width: {
      type: String,
      default: '100',
    },
    height: {
      type: String,
      default: '100',
    },
  },
  data() {
    return {
      canvasId: '',
    };
  },
  computed: {},
  created() {
    this.canvasId = this.getUUID();
    this.$nextTick(() => {
      this.init();
    });
  },
  mounted() {},
  methods: {
    init() {
      const { width } = this;
      const { height } = this;
      QRCode.toCanvas(
        document.getElementById(this.canvasId),
        this.content,
        { width, height, toSJISFunc: QRCode.toSJIS },
        (error) => {},
      );
    },
    getUUID() {
      let d = new Date().getTime();
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        (c) => {
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
        },
      );
      return uuid;
    },

  },
  watch: {
    content(val) {
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
    .QRCode {
      display: block;
      position: relative;
      overflow: hidden;
      margin: auto;
    }
</style>
