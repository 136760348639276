import fetch from './http';
export default {


  /**
   * [用户登录]
   * @param  {[type]} params [description]
   * @return {[type]}        [description]
   */
  signin(params) {
    return fetch.fetchPost('shopapi/login/login', params);
  },
  /**
   * [用户注册]
   * @param  {[type]} params [description]
   * @return {[type]}        [description]
   */
  signup(params) {
    return fetch.fetchPost('shopapi/register/sysRegister', params);
  },
  /**
   * [登录到品牌]
   * @param  {[type]} params [description]
   * @return {[type]}        [description]
   */
  getBrandToken(params) {
    return fetch.fetchPost('shopapi/site/getToken', params);
  },

  /**
     * [获取登录验证码标识]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getCaptcha(params) {
    return fetch.fetchPost('shopapi/captcha/captcha', params);
  },

  /**
     * [获取品牌列表]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getBrandList(params) {
    return fetch.fetchPost('/homeapi/site/getUserAuthSiteList', params);
  },

  /**
     * [获取品牌信息]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getBrandInfo(params) {
    return fetch.fetchPost('/shopapi/site/shopInfo', params);
  },

  /**
     * [获取直播间列表]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getRoomList(params) {
    return fetch.fetchPost('bxlive/shopapi/room/getRoomList', params);
  },

  /**
     * [获取直播间信息]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getRoomInfo(params) {
    return fetch.fetchPost('bxlive/shopapi/live/getRoomInfo', params);
  },

  /**
     * [获取直播间鉴权信息]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  genUserSig(params) {
    return fetch.fetchPost('/shopapi/trtc/genUserSig', params);
  },

  /**
     * [创建品牌]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  createBrand(params) {
    return fetch.fetchPost('shopapi/site/add', params);
  },

  /**
     * [创建直播间]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  createRoom(params) {
    return fetch.fetchPost('shopapi/site/createRoom', params);
  },

  /**
     * [发送验证码]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getSmsCode(params) {
    return fetch.fetchPost('shopapi/register/getSmsCode', params);
  },


  /**
     * [获取直播间商品]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getGoodsListByRoom(params) {
    return fetch.fetchPost('bxlive/shopapi/goods/getGoodsListByRoom', params);
  },

  /**
     * [商品上链接]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  putGoods(params) {
    return fetch.fetchPost('shopapi/goods/putGoods', params);
  },
  /**
   * [商品下链接]
   * @param  {[type]} params [description]
   * @return {[type]}        [description]
   */
  descendGoods(params) {
    return fetch.fetchPost('shopapi/goods/descendGoods', params);
  },

  /**
     * [商品上下架]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  switchGoodsState(params) {
    return fetch.fetchPost('shopapi/trtc/modifyGoodsState', params);
  },

  /**
     * [获取虚拟人数设置]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getfakePersonSetting(params) {
    return fetch.fetchPost('shopapi/trtc/fakePersonSetting', params);
  },

  /**
     * [设置虚拟人数]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  setFakePersonSetting(params) {
    return fetch.fetchPost('shopapi/trtc/setFakePersonSetting', params);
  },

  /**
     * [获取直播观看条件 门票&密码]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getviewConditionSetting(params) {
    return fetch.fetchPost('shopapi/trtc/viewConditionSetting', params);
  },

  /**
     * [设置直播观看条件 门票&密码]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  setViewConditionSetting(params) {
    return fetch.fetchPost('shopapi/trtc/setViewConditionSetting', params);
  },

  /**
     * [发送虚拟订单]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  virtualOrder(params) {
    return fetch.fetchPost('shopapi/trtc/virtualOrder', params);
  },

  /**
     * [创建红包]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  addPacket(params) {
    return fetch.fetchPost('shopapi/trtc/addRedPacket', params);
  },

  /**
     * [发送红包]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  sendPacket(params) {
    return fetch.fetchPost('shopapi/trtc/sendRedPacket', params);
  },


  /* 数字大屏板块API */
  /**
     * [获取直播间商品销量排行]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getGoodsRanking(params) {
    return fetch.fetchPost('shopapi/trtc/goodsRanking', params);
  },

  /**
     * [获取邀请排行]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getInviteRanking(params) {
    return fetch.fetchPost('shopapi/trtc/inviteRanking', params);
  },

  /**
     * [获取消费排行]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getConsumerRanking(params) {
    return fetch.fetchPost('shopapi/trtc/consumerRanking', params);
  },
  /**
     * [获取在线人数记录]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getOnlineList(params) {
    return fetch.fetchPost('shopapi/trtc/onlineList', params);
  },
  /**
     * [直播间订单比例]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getOrderProportion(params) {
    return fetch.fetchPost('shopapi/trtc/orderProportion', params);
  },

  /**
     * [总转化率]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getConversionRate(params) {
    return fetch.fetchPost('shopapi/trtc/conversionRate', params);
  },

  /**
     * [统计总数据]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getStatistics(params) {
    return fetch.fetchPost('shopapi/trtc/statistics', params);
  },

  /**
     * [裂变统计]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getFissionStatistics(params) {
    return fetch.fetchPost('shopapi/trtc/fissionStatistics', params);
  },

  /**
     * [裂变统计]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
  getUnpaidOrders(params) {
    return fetch.fetchPost('shopapi/trtc/unpaidOrders', params);
  },


};
