/**
 * 全局工具类方法
 */
export default {

  /**
   * 处理图片url
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  handleUrl(data) {
    let src;
    if (data.indexOf('http') < 0) {
      src = `https://dev-panel.mashplay.cn/${data}`;
    } else {
      src = data;
    }
    return src;
  },


  /**
   * 深度克隆
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  deepClone(data) {
    let _tmp; let result;
    _tmp = JSON.stringify(data);
    result = JSON.parse(_tmp);
    return result;
  },


  /**
   * 数组位置对调
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  swapArray(arr, oldIndex, newIndex) {
    // 下移
    // 如果当前元素在拖动目标位置的下方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置的地方新增一个和当前元素值一样的元素，
    // 我们再把数组之前的那个拖动的元素删除掉，所以要len+1
    if (newIndex > oldIndex) {
      // 如果当前元素在拖动目标位置的上方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置+1的地方新增一个和当前元素值一样的元素，
      // 这时，数组len不变，我们再把数组之前的那个拖动的元素删除掉，下标还是index
      arr.splice(newIndex + 1, 0, arr[oldIndex]);
      arr.splice(oldIndex, 1);
    }
    // 上移
    else {
      arr.splice(newIndex, 0, arr[oldIndex]);
      arr.splice(oldIndex + 1, 1);
    }


    return arr;
  },

  /**
   * 设置storage
   * @param  {[type]} name [description]
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  setStorage(name, data) {
    return localStorage.setItem(name, JSON.stringify(data));
  },

  /**
  * 获取storage
  * @param  {[type]} name [description]
  * @return {[type]}      [description]
  */
  getStorage(name) {
    const data = localStorage.getItem(name);
    return JSON.parse(data);
  },

  /**
   * 删除storage
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  removeStorage(name) {
    return localStorage.removeItem(name);
  },

  /**
   * 删除所有storage
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  removeAllStorage(name) {
    return localStorage.clear();
  },

  /**
   * 图片路径转换
   * @param {String} img_path 图片地址
   * @param {Object} params 参数，针对商品、相册里面的图片区分大中小，size: big、mid、small
   */
  img(img_path, params) {
    var path = "";
    if (img_path && img_path != undefined && img_path != "") {
      if (params && img_path != this.getDefaultImage().default_goods_img) {
        // 过滤默认图
        let arr = img_path.split(".");
        let suffix = arr[arr.length - 1];
        arr.pop();
        arr[arr.length - 1] = arr[arr.length - 1] + "_" + params.size.toUpperCase();
        arr.push(suffix);
        img_path = arr.join(".");
      }
      if (img_path.indexOf("http://") == -1 && img_path.indexOf("https://") == -1) {
        path = "http://resources.oz4.cn/" + "/" + img_path;
      } else {
        path = img_path;
      }
      // 处理商品助手的图片路径
      path = path.replace("addons/NsGoodsAssist/", "").replace("shop/goods/", "");
    }
    return path;
  },

  //获取当前时间
  date(time, fmt) {
    var date = new Date();
        var seperator1 = "-";
        var seperator2 = ":";
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        var nowHours = date.getHours();
        var nowMinutes = date.getMinutes();
        var nowSeconds = date.getSeconds();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        if (nowHours >= 0 && nowHours <= 9) {
            nowHours = "0" + nowHours
        }
        if (nowMinutes >= 0 && nowMinutes <= 9) {
            nowMinutes = "0" + nowMinutes
        }
        if (nowSeconds >= 0 && nowSeconds <= 9) {
            nowSeconds = "0" + nowSeconds
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
                + " " + nowHours + seperator2 + nowMinutes
                + seperator2 + nowSeconds;
        return currentdate;
  }
};
